import logo from './logo.svg';
import { useEffect } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';
import { MessengerChat } from "react-messenger-chat-plugin";

function Contact() {
    useEffect(()=>{

        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
        ReactGA.pageview('Newborn Pricing View');

        $('.mob-nav').on('click', function () {
            $(this).toggleClass('active');
            $(this).find('i').toggleClass('fa-bars fa-times');
            $('#topmenu').toggleClass('open');
            $('.header_top_bg.header_trans-fixed').toggleClass('open');
            if($('#topmenu').hasClass('active-socials')){
                $('.header_top_bg.header_trans-fixed').addClass('open');
            }
            $('body, html').toggleClass('no-scroll');
            return false;
        });

    }) 
  return (
    <div className="App">
         <MessengerChat
    pageId="109554184988419"
    language="sv_SE"
    themeColor={"#000000"}
    bottomSpacing={300}
    loggedInGreeting="loggedInGreeting"
    loggedOutGreeting="loggedOutGreeting"
    greetingDialogDisplay={"show"}
    debugMode={true}
    onMessengerShow={() => {
      console.log("onMessengerShow");
    }}
    onMessengerHide={() => {
      console.log("onMessengerHide");
    }}
    onMessengerDialogShow={() => {
      console.log("onMessengerDialogShow");
    }}
    onMessengerDialogHide={() => {
      console.log("onMessengerDialogHide");
    }}
    onMessengerMounted={() => {
      console.log("onMessengerMounted");
    }}
    onMessengerLoad={() => {
      console.log("onMessengerLoad");
    }}
  />
     <div class="preloader-svg">
    <svg id="loaderSvg" class="loader__svg" xmlns="http://www.w3.org/2000/svg" height="200" viewBox="0 0 1024 300">
    <defs>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern1"
            viewBox="0 0 1024 300">
            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                   xlinkHref="assets/portfolio/1.jpg"
                   preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern2"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/2.png"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern3"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/3.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern4"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/4.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern5"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/5.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
        </defs>
        <text x="50%" y="20%" id="letter" dy="150"
              fill="url('#pattern1')">
            OMG
        </text>
    </svg>
</div>
<div class="main-wrapper ">
    <div class="header_top_bg fixed-header">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">

                    
                <header class="right-menu ">
                        
                        <a href="/" class="logo">

                        <img src="./assets/images/Logo-Draft-Black.png"/> </a>
                        

                        
                        <a href="#" class="mob-nav">
                            <i class="fa fa-bars"></i>
                        </a>
                        

                        
                        <a href="#" class="aside-nav">
                            <span class="aside-nav-line line-1"></span>
                            <span class="aside-nav-line line-2"></span>
                            <span class="aside-nav-line line-3"></span>
                        </a>
                        

                        
                        <nav id="topmenu" class="topmenu">
                        <ul class="menu">
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children mega-menu menu-home">
                                    <a href="/home">HOME</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="#">PORTFOLIO</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/portfolio">Maternity Gallery</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/newbornportfolio">Newborn Gallery</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/collection">Maternity Gowns</a>
                                        </li>
                                    </ul>
                                      
                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent ">
                                    <a href="#">PRICING</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/investment">Maternity Session Pricing</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/newborninvestment">Newborn Session Pricing</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/bundleinvestment">Maternity & Newborn Bundle</a>
                                        </li>
                                    </ul>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/aboutus">ABOUT US</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/faq">FAQ</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom  ">
                                    <a href="/contact">ENQUIRE NOW</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="https://forms.gle/9WfQamuQ1jGTBWQ26" target="_blank">Model Search</a>
                                    
                                </li>
                               
                            </ul>
                            <div class="f-right">
                                <div class="whizz-top-social">
                                    <span class="social-icon fa fa-share-alt"></span>
                                    <ul class="social">
                                        <li><a href="https://www.facebook.com/ohmygoddessphotography" target="_blank"><i
                                                class="fa fa-facebook"></i></a></li>
                                        
                                        <li><a href="https://instagram.com/ohmygoddessphotography" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                               
                            </div>
                        </nav>
                        
                        <a href="#" class="socials-mob-but">
                            <i class="fa fa-share-alt"></i>
                        </a>
                    </header>
                </div>
            </div>

        </div>
    </div>
    <div class="container-full">
        <div class="hero">
            <img src="./assets/portfolio/pricing/OMGUpdatedNewborn.png" style={{width:"40%", marginTop:"200px"}}/>
           
    </div>

</div>

</div>
</div>
  );
}

export default Contact;
