import React from 'react';
import { Link } from "react-router-dom";


function Error() {
  
  return (
   <div>
       <div class="preloader-svg">
    <svg id="loaderSvg" class="loader__svg" xmlns="http://www.w3.org/2000/svg" height="300" viewBox="0 0 1024 300">
        <defs>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern1"
            viewBox="0 0 1024 300">
            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                   xlinkHref="assets/images/stock-photo-189411801-1024x682.jpg"
                   preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern2"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/images/stock-photo-123105215-1024x682.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern3"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/images/stock-photo-138439815-1024x682.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern4"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/images/stock-photo-159062461-e1509005459415-1024x670.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern5"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/images/stock-photo-201395315-1024x682.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
        </defs>
        <text x="50%" y="20%" id="letter" dy="150"
              fill="url('#pattern1')">
            OMG
        </text>
    </svg>
</div>
<div class="main-wrapper ">
    <div class="header_top_bg">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">

                    
                <header class="right-menu ">
                        
                        <a href="/" class="logo">

                        <img src="./assets/images/Logo-Draft-Black.png"/> </a>
                        

                        
                        <a href="#" class="mob-nav">
                            <i class="fa fa-bars"></i>
                        </a>
                        

                        
                        <a href="#" class="aside-nav">
                            <span class="aside-nav-line line-1"></span>
                            <span class="aside-nav-line line-2"></span>
                            <span class="aside-nav-line line-3"></span>
                        </a>
                        

                        
                        <nav id="topmenu" class="topmenu">
                            <ul class="menu">
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor menu-home">
                                    <a href="/home">HOME</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/portfolio">PORTFOLIO</a>
                                      
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/collection">OUR COLLECTION</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/investment">INVESTMENT</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom  ">
                                    <a href="/aboutus">ABOUT US</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/faq">FAQ</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/contact">ENQUIRE NOW</a>
                                    
                                </li>
                               
                            </ul>
                            <div class="f-right">
                                <div class="whizz-top-social">
                                    <span class="social-icon fa fa-share-alt"></span>
                                    <ul class="social">
                                        <li><a href="https://www.facebook.com/ohmygoddessphotography" target="_blank"><i
                                                class="fa fa-facebook"></i></a></li>
                                        
                                        <li><a href="https://instagram.com/ohmygoddessphotography" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                               
                            </div>
                        </nav>
                        
                        <a href="#" class="socials-mob-but">
                            <i class="fa fa-share-alt"></i>
                        </a>
                    </header>
                </div>
            </div>

        </div>
    </div>
    <div class="container">
        <div class="hero">
            <div class="vc_row vc_row-fluid vc_row-o-full-height vc_row-o-columns-middle vc_row-flex" style={{minHeight:"50vh"}}><div class="vc_column_container vc_col-sm-12 "><div class="vc_column-inner vc_custom_1481728501456"><div>
                
                    <div class="coming-page-wrapper">
                        <h2 class="title">This Link is Broken</h2>
                       
                        
                        <a href="/home" class="a-btn-2">go home<i class="fa fa-caret-right" aria-hidden="true"></i>
                        </a>
                    </div>
                

            </div></div></div><div class="kswr-column-settings" data-minheight="0px" data-isminheight="off" data-theclasses="kswr_pnone kswr_mnone kswr_bnone"></div></div><div class="kswr-row-element-back" data-classes="kswr_pnone kswr_mnone kswr_bnone"></div>
        </div>
    </div>
 
   </div>
    </div>
    
  );
}
export default Error;