import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  Route,
  BrowserRouter,
  Routes
} from 'react-router-dom'

import Home from './Home';
import AboutUs from './About';
import Portfolio from './Portfolio';
import NewbornPortfolio from './NewbornPortfolio';
import Collection from './Collection';
import FAQ from './FAQ';
import Contact from "./Contact";
import MaternityInvestment from "./MaternityInvestment";
import NewbornInvestment from "./NewBornInvestment";
import BundleInvestment from "./BundleInvestment";
import Error from "./Error";
import About from './About';
import ReactGA from 'react-ga';

ReactDOM.render(
  <BrowserRouter>
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/home" element={<Home />} />
    <Route path="faq" element={<FAQ />} />
    <Route path="portfolio" element={<Portfolio />} />
    <Route path="portfolio" element={<Portfolio />} />
    <Route path="newbornportfolio" element={<NewbornPortfolio />} />
    <Route path="collection" element={<Collection />} />
    <Route path="contact" element={<Contact />} />
    <Route path="investment" element={<MaternityInvestment />} />
    <Route path="newborninvestment" element={<NewbornInvestment />} />
    <Route path="bundleinvestment" element={<BundleInvestment />} />
    <Route path="aboutus" element={<About />} />
    <Route
      path="*"
      element={<Error />}
    />
  </Routes>
</BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
