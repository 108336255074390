import logo from './logo.svg';
import { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';
import emailjs from '@emailjs/browser';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Contact(props) {
    const form = useRef();

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState('');

    const [email, setEmail] = useState('');

    const [mobile, setMobile] = useState('');
    const [mobileError, setMobileError] = useState('');

    const [sessionType, setSessionType] = useState('');

    const [weeks, setWeeks] = useState('');
    const [dueDate, setDueDate] = useState(new Date());

    const [type, setType] = useState('');
    const [typeError, setTypeError] = useState('');

    const [query, setQuery] = useState('');
    const [queryError, setQueryError] = useState('');

    const [birthDate, setBirthDate] = useState(new Date());
    const [days, setDays] = useState('');

    const [showForm, setShowForm] = useState(true);

    useEffect(() => {

        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
        ReactGA.pageview('Contact Us View');

        $('.mob-nav').on('click', function () {
            $(this).toggleClass('active');
            $(this).find('i').toggleClass('fa-bars fa-times');
            $('#topmenu').toggleClass('open');
            $('.header_top_bg.header_trans-fixed').toggleClass('open');
            if ($('#topmenu').hasClass('active-socials')) {
                $('.header_top_bg.header_trans-fixed').addClass('open');
            }
            $('body, html').toggleClass('no-scroll');
            return false;
        });

    })

    const submitForm = (e) => {
        e.preventDefault();


        if(name === "" || name.length <3 ){
            setNameError("Please enter a valid name")
        }
        else{
            setNameError("")
        }

        if(mobile === "" || mobile.length <10 ){
            setMobileError("Please enter a valid mobile number")
        }
        else{
            setMobileError("")
        }

        if(query === "" ){
            setQueryError("Please enter a valid query")
        }
        else{
            setQueryError("")
        }

        if (nameError === "" && mobileError === "" && queryError === "") {
            emailjs.sendForm('service_95o75x1', 'template_4w0o60o', form.current, 'U0EmrXZMS0QNQIpAc')
                .then((result) => {
                    setShowForm(false);

                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
        }
    };

    return (
        <div className="App">
            <div class="preloader-svg">
                <svg id="loaderSvg" class="loader__svg" xmlns="http://www.w3.org/2000/svg" height="200" viewBox="0 0 1024 300">
                    <defs>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern1"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/1.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern2"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/2.png"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern3"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/3.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern4"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/4.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern5"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/5.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                    </defs>
                    <text x="50%" y="20%" id="letter" dy="150"
                        fill="url('#pattern1')">
                        OMG
                    </text>
                </svg>
            </div>
            <div class="main-wrapper ">
                <div class="header_top_bg fixed-header">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">


                                <header class="right-menu ">

                                    <a href="/" class="logo">

                                        <img src="./assets/images/Logo-Draft-Black.png" /> </a>



                                    <a href="#" class="mob-nav">
                                        <i class="fa fa-bars"></i>
                                    </a>



                                    <a href="#" class="aside-nav">
                                        <span class="aside-nav-line line-1"></span>
                                        <span class="aside-nav-line line-2"></span>
                                        <span class="aside-nav-line line-3"></span>
                                    </a>



                                    <nav id="topmenu" class="topmenu">
                                        <ul class="menu">
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children mega-menu menu-home">
                                                <a href="/home">HOME</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="#">PORTFOLIO</a>
                                                <ul class="sub-menu">
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/portfolio">Maternity Gallery</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/newbornportfolio">Newborn Gallery</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/collection">Maternity Gowns</a>
                                                    </li>
                                                </ul>

                                            </li>

                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="#">PRICING</a>
                                                <ul class="sub-menu">
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/investment">Maternity Session Pricing</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/newborninvestment">Newborn Session Pricing</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/bundleinvestment">Maternity & Newborn Bundle</a>
                                                    </li>
                                                </ul>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="/aboutus">ABOUT US</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="/faq">FAQ</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent  ">
                                                <a href="/contact">ENQUIRE NOW</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="https://forms.gle/9WfQamuQ1jGTBWQ26" target="_blank">Model Search</a>

                                            </li>

                                        </ul>
                                        <div class="f-right">
                                            <div class="whizz-top-social">
                                                <span class="social-icon fa fa-share-alt"></span>
                                                <ul class="social">
                                                    <li><a href="https://www.facebook.com/ohmygoddessphotography" target="_blank"><i
                                                        class="fa fa-facebook"></i></a></li>

                                                    <li><a href="https://instagram.com/ohmygoddessphotography" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </nav>

                                    <a href="#" class="socials-mob-but">
                                        <i class="fa fa-share-alt"></i>
                                    </a>
                                </header>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="container-full">
                    <div class="hero">
                        <div class="wpb_wrapper">
                            <div class="contacts-info-wrap style3 full-height over">

                                <div class="content-wrap">
                                    <div class="image-wrap full-height parallax-window full-height"
                                        data-parallax="scroll"
                                        data-position-Y="top"
                                        data-position-X="center"
                                        data-image-src="./assets/portfolio/contact.png">
                                    </div>

                                    <div class="content">

                                        {!showForm && <>   <h2 class="title-main">Thanks for your enquiry</h2>
                                            <div class="text">We will get back to you in 1-2 business days.</div>
                                            <div class="row"></div>
                                            <div class="row">
                                                <div class="col-xs-12 text-center"><input type="submit"
                                                    value="Submit another Query"
                                                    class="wpcf7-form-control wpcf7-submit" onClick={e => setShowForm(true)} />
                                                </div>
                                            </div>

                                        </>}

                                        {showForm && <>   <h2 class="title-main">Get in touch</h2>
                                            <div class="text">Please write to us if you have any questions about the various sessions we offer.
                                                Please provide as much information as you can so we can cater to your queries faster.


                                            </div>
                                            <div class="form-wrap">
                                                <div role="form" class="wpcf7" id="wpcf7-f2623-p4987-o1" lang="en-US"
                                                    dir="ltr">
                                                    <div class="screen-reader-response"></div>
                                                    <form method="post"
                                                        class="wpcf7-form" ref={form} onSubmit={submitForm}>
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-12">
                                                                    <label style={{ textAlign: "left" }}>NAME <span style={{ color: "red" }}>*</span><br />
                                                                        <span class="wpcf7-form-control-wrap your-text"><input
                                                                            type="text" name="name" value={name}
                                                                            size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            placeholder="Your name" onChange={e => setName(e.target.value)} /></span>
                                                                        {nameError !== "" && <span style={{ color: "red", textAlign: "left", fontWeight: "normal" }}>{nameError}</span>}
                                                                    </label>

                                                                </div>

                                                            </div>
                                                            <div class="row">

                                                                <div class="col-xs-12 col-md-12">
                                                                    <label style={{ textAlign: "left" }}>EMAIL<br />
                                                                        <span class="wpcf7-form-control-wrap your-email"><input
                                                                            type="email" name="email" value={email}
                                                                            size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                            aria-required="true" aria-invalid="false"
                                                                            placeholder="E-mail for communication" onChange={e => setEmail(e.target.value)} /></span><br />
                                                                    </label>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-12">
                                                                    <label style={{ textAlign: "left" }}>MOBILE<span style={{ color: "red" }}>*</span><br />
                                                                        <span class="wpcf7-form-control-wrap your-text"><input
                                                                            type="text" name="mobile" value={mobile}
                                                                            size="40"
                                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            placeholder="Best Number to contact you" onChange={e => setMobile(e.target.value)} /></span>
                                                                        {mobileError !== "" && <span style={{ color: "red", textAlign: "left", fontWeight: "normal" }}>Please enter a valid mobile number</span>}
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            <div class="row">
                                                                <div class="col-xs-12 col-md-12">
                                                                    <label style={{ textAlign: "left" }}>TYPE OF SESSION YOU ARE INTERESTED IN<span style={{ color: "red" }}>*</span><br />
                                                                        <span class="wpcf7-form-control-wrap your-text">
                                                                            <select name="type" class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                aria-required="true" aria-invalid="false" style={{ width: "100%", height: "30px" }}
                                                                                onChange={e => setType(e.target.value)} selected={type}>

                                                                                <option value="">Session</option>
                                                                                <option value="Maternity">Maternity</option>
                                                                                <option value="Newborn">Newborn</option>
                                                                                <option value="Other">Other</option>
                                                                            </select>
                                                                        </span><br />
                                                                    </label>
                                                                </div>

                                                            </div>
                                                            {type === "Maternity" &&
                                                                <div class="row">
                                                                    <div class="col-xs-12 col-md-6">
                                                                        <label style={{ textAlign: "left" }}>TENTATIVE DUE DATE<br />
                                                                            <span class="wpcf7-form-control-wrap your-text">
                                                                                <DatePicker selected={dueDate} dateFormat="dd/MM/yyyy" onChange={(date) => setDueDate(date)} />
                                                                            </span><br />
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-xs-12 col-md-6">
                                                                        <label style={{ textAlign: "left" }}>No. OF WEEKS<br />
                                                                            <span class="wpcf7-form-control-wrap your-text">
                                                                                <input
                                                                                    type="text" name="weeks" value=""
                                                                                    size="40"
                                                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                    aria-required="true" aria-invalid="false"
                                                                                    placeholder="Number of Weeks currently" />
                                                                            </span><br />
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                            }

                                                            {type === "Newborn" &&
                                                                <div class="row">
                                                                    <div class="col-xs-12 col-md-6">
                                                                        <label style={{ textAlign: "left" }}>BABY's BIRTH DATE<br />
                                                                            <span class="wpcf7-form-control-wrap your-text">
                                                                                <DatePicker selected={birthDate} dateFormat="dd/MM/yyyy" onChange={(date) => setBirthDate(date)} />
                                                                            </span><br />
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-xs-12 col-md-6">
                                                                        <label style={{ textAlign: "left" }}>No. OF DAYS<br />
                                                                            <span class="wpcf7-form-control-wrap your-text">
                                                                                <input
                                                                                    type="text" name="weeks" value={days}
                                                                                    size="40"
                                                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                                    aria-required="true" aria-invalid="false"
                                                                                    placeholder="How many days new is the little one ?" onChange={e => setDays(e.target.value)} />
                                                                            </span><br />
                                                                        </label>
                                                                    </div>

                                                                </div>
                                                            }

                                                            <div class="row">
                                                                <div class="col-xs-12">
                                                                    <label style={{ textAlign: "left" }}>QUESTION/QUERY/COMMENT<span style={{ color: "red" }}>*</span><br />
                                                                        <span class="wpcf7-form-control-wrap your-textarea"><textarea
                                                                            name="query" cols="40" rows="10"
                                                                            class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                                                                            aria-required="true" aria-invalid="false"
                                                                            placeholder="Please specify some details on your query" value={query} onChange={e => setQuery(e.target.value)}></textarea></span>
                                                                        {queryError !== "" && <span style={{ color: "red", textAlign: "left", fontWeight: "normal" }}>Please enter some  text</span>}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-xs-12 text-center"><input type="submit"
                                                                    value="SUBMIT"
                                                                    class="wpcf7-form-control wpcf7-submit" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </>}
                                    </div>


                                </div>

                            </div>


                        </div>
                    </div>
                    <footer id="footer" class="">
                        <div class="container-fluid">
                            <div class="copyright text-center">
                                Oh My Goddess Photography © 2022
                            </div>
                        </div>
                    </footer>
                </div>

            </div>
        </div>
    );
}

export default Contact;
