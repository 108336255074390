import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';
import AwesomeSlider from 'react-awesome-slider';
import CoreStyles from 'react-awesome-slider/src/core/styles.scss';
import AnimationStyles from 'react-awesome-slider/src/styled/fold-out-animation/fold-out-animation.scss';

function Home() {



    useEffect(() => {
        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
        ReactGA.pageview('Home View');

        $('.mob-nav').on('click', function () {
            $(this).toggleClass('active');
            $(this).find('i').toggleClass('fa-bars fa-times');
            $('#topmenu').toggleClass('open');
            $('.header_top_bg.header_trans-fixed').toggleClass('open');
            if ($('#topmenu').hasClass('active-socials')) {
                $('.header_top_bg.header_trans-fixed').addClass('open');
            }
            $('body, html').toggleClass('no-scroll');
            return false;
        });

    })
    return (
        <div className="App">
            <div class="preloader-svg">
                <svg id="loaderSvg" class="loader__svg" xmlns="http://www.w3.org/2000/svg" height="200" viewBox="0 0 1024 300">
                    <defs>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern1"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/1.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern2"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/2.png"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern3"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/3.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern4"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/4.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                        <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern5"
                            viewBox="0 0 1024 300">
                            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                                xlinkHref="assets/portfolio/5.jpg"
                                preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
                        </pattern>
                    </defs>
                    <text x="50%" y="20%" id="letter" dy="150"
                        fill="url('#pattern1')">
                        OMG
                    </text>
                </svg>
            </div>
            <div class="main-wrapper   padding-desc  padding-mob ">
                <div class="header_top_bg  header_trans-fixed">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">


                                <header class="right-menu ">

                                    <a href="/" class="logo">

                                        <img src="./assets/images/Logo-Draft-white.png" /> </a>



                                    <a href="#" class="mob-nav">
                                        <i class="fa fa-bars"></i>
                                    </a>



                                    <a href="#" class="aside-nav">
                                        <span class="aside-nav-line line-1"></span>
                                        <span class="aside-nav-line line-2"></span>
                                        <span class="aside-nav-line line-3"></span>
                                    </a>



                                    <nav id="topmenu" class="topmenu">
                                        <ul class="menu">
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent menu-item-has-children mega-menu menu-home">
                                                <a href="/home">HOME</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="#">PORTFOLIO</a>
                                                <ul class="sub-menu">
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/portfolio">Maternity Gallery</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/newbornportfolio">Newborn Gallery</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/collection">Maternity Gowns</a>
                                                    </li>
                                                </ul>

                                            </li>

                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="#">PRICING</a>
                                                <ul class="sub-menu">
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/investment">Maternity Session Pricing</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/newborninvestment">Newborn Session Pricing</a>
                                                    </li>
                                                    <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                                        <a href="/bundleinvestment">Maternity & Newborn Bundle</a>
                                                    </li>
                                                </ul>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="/aboutus">ABOUT US</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="/faq">FAQ</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="/contact">ENQUIRE NOW</a>

                                            </li>
                                            <li
                                                class="menu-item menu-item-type-custom menu-item-object-custom ">
                                                <a href="https://forms.gle/9WfQamuQ1jGTBWQ26" target="_blank">Model Search</a>

                                            </li>

                                        </ul>
                                        <div class="f-right">
                                            <div class="whizz-top-social">
                                                <span class="social-icon fa fa-share-alt"></span>
                                                <ul class="social">
                                                    <li><a href="https://www.facebook.com/ohmygoddessphotography" target="_blank"><i
                                                        class="fa fa-facebook"></i></a></li>
                                                    <li><a href="https://instagram.com/ohmygoddessphotography" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </nav>

                                    <a href="#" class="socials-mob-but">
                                        <i class="fa fa-share-alt"></i>
                                    </a>
                                </header>
                            </div>
                        </div>

                    </div>
                </div>
                {/*<div class=" ">
        <div class="parallax-window full-height" data-parallax="scroll" data-position-Y="top"
             data-image-src="./assets/portfolio/1.jpg">
            <div class="content-parallax">
                
            </div>
        </div>

       

        <div class="parallax-window full-height" data-parallax="scroll" data-position-Y="top"
             data-image-src="./assets/portfolio/2.png">
        </div>

        <div class="parallax-window full-height" data-parallax="scroll" data-position-Y="top"
             data-image-src="./assets/portfolio/3.jpg">
        </div>

        <div class="parallax-window full-height" data-parallax="scroll" data-position-Y="top"
             data-image-src="./assets/portfolio/4.jpg">
        </div>

  </div> */ }

                {/*

<div class="hero">
            <div class="wpb_wrapper">
                            <div class="outer-swiper-wrapper">
                                <div class="img-overlay"></div>
                                <div class="swiper-container-vert-slider" data-autoplay="5000"
                                     data-speed="1500" data-loop="1">
                                    <div class="swiper-wrapper">
                                        <div class="swiper-slide s-back-switch swiper3-lazy"
                                             data-background="./assets/portfolio/100.jpg"
                                             data-bg="./assets/portfolio/100.jpg">
                                            <div class="swiper3-lazy-preloader"></div>
                                            
                                        </div>
                                        <div class="swiper-slide s-back-switch swiper3-lazy"
                                             data-background="./assets/portfolio/400.jpg"
                                             data-bg="./assets/portfolio/400.jpg">
                                            <div class="swiper3-lazy-preloader"></div>
                                            
                                        </div>
                                        <div class="swiper-slide s-back-switch swiper3-lazy"
                                             data-background="./assets/portfolio/300.jpg"
                                             data-bg="./assets/portfolio/300.jpg">
                                            <div class="swiper3-lazy-preloader"></div>
                                            
                                        </div>
                                        <div class="swiper-slide s-back-switch swiper3-lazy"
                                             data-background="./assets/portfolio/500.jpg"
                                             data-bg="./assets/portfolio/500.jpg">
                                            <div class="swiper3-lazy-preloader"></div>
                                            
                                        </div>
                                        <div class="swiper-slide s-back-switch swiper3-lazy"
                                             data-background="./assets/portfolio/1.jpg"
                                             data-bg="./assets/portfolio/1.jpg">
                                            <div class="swiper3-lazy-preloader"></div>
                                           
                                        </div>
                                        <div class="swiper-slide s-back-switch swiper3-lazy"
                                             data-background="./assets/portfolio/2.jpg"
                                             data-bg="./assets/portfolio/2.jpg">
                                            <div class="swiper3-lazy-preloader"></div>
                                            
                                        </div>
                                        
                                    </div>
                                    
                                    <div class="swiper-button swiper-button-next"></div>
                                    <div class="swiper-button swiper-button-prev"></div>
                                </div>
                            </div>
                        </div>
        </div>

*/}

<AwesomeSlider
    animation="foldOutAnimation"
    cssModule={[CoreStyles, AnimationStyles]}
  >
  <div data-src="./assets/portfolio/1000.png" />    
    <div data-src="./assets/portfolio/900.jpg" />    
    <div data-src="./assets/portfolio/700.jpg" />
    <div data-src="./assets/portfolio/800.jpg" />
    <div data-src="./assets/portfolio/400.jpg" />

    <div data-src="./assets/portfolio/910.jpg" />
    <div data-src="./assets/portfolio/100.jpg" />    
    
    
  </AwesomeSlider>

                {/* <div class="hero">
                    <div class="row ">
                        <div class="col-sm-12">
                            <div class="wpb_wrapper">
                            <div class="swiper3-container portfolio-slider-wrapper full-height slider_simple"
                            data-mouse="0" data-autoplay="5000"
                                    data-loop="1" data-speed="1000" data-center="1"
                                    data-space="0">
                                    <div class="swiper3-wrapper full-height ">
                                        <div class="swiper3-slide">

                                            <a href="#"
                                                class="images-slider-wrapper clearfix"
                                                target="_self">
                                                <div class="images-one swiper3-lazy"
                                                    data-background="./assets/portfolio/700.jpg">
                                                    <div class="swiper3-lazy-preloader"></div>
                                                </div>
                                            </a>


                                        </div>
                                        <div class="swiper3-slide">

                                            <a href="#"
                                                class="images-slider-wrapper clearfix"
                                                target="_self">
                                                <div class="images-one swiper3-lazy"
                                                    data-background="./assets/portfolio/400.jpg">
                                                    <div class="swiper3-lazy-preloader"></div>
                                                </div>
                                            </a>



                                        </div>
                                        <div class="swiper3-slide">

                                            <a href="#"
                                                class="images-slider-wrapper clearfix"
                                                target="_self">
                                                <div class="images-one swiper3-lazy"
                                                    data-background="./assets/portfolio/800.jpg">
                                                    <div class="swiper3-lazy-preloader"></div>
                                                </div>
                                            </a>


                                        </div>

                                        <div class="swiper3-slide">

                                            <a href="#"
                                                class="images-slider-wrapper clearfix"
                                                target="_self">
                                                <div class="images-one swiper3-lazy"
                                                    data-background="./assets/portfolio/300.jpg">
                                                    <div class="swiper3-lazy-preloader"></div>
                                                </div>
                                            </a>


                                        </div>

                                        <div class="swiper3-slide">

                                            <a href="#"
                                                class="images-slider-wrapper clearfix"
                                                target="_self">
                                                <div class="images-one swiper3-lazy"
                                                    data-background="./assets/portfolio/1.jpg">
                                                    <div class="swiper3-lazy-preloader"></div>
                                                </div>
                                            </a>


                                        </div>

                                        <div class="swiper3-slide">

                                            <a href="#"
                                                class="images-slider-wrapper clearfix"
                                                target="_self">
                                                <div class="images-one swiper3-lazy"
                                                    data-background="./assets/portfolio/22.jpg">
                                                    <div class="swiper3-lazy-preloader"></div>
                                                </div>
                                            </a>


                                        </div>

                                        <div class="swiper3-slide">

                                            <a href="#"
                                                class="images-slider-wrapper clearfix"
                                                target="_self">
                                                <div class="images-one swiper3-lazy"
                                                    data-background="./assets/portfolio/100.jpg">
                                                    <div class="swiper3-lazy-preloader"></div>
                                                </div>
                                            </a>


                                        </div>
                                    </div>
                                    <div class="pagination"></div>
                                </div>
                                <div class="swiper3-outer-left portfolio-pagination slider_simple"></div>
                                <div class="swiper3-outer-right portfolio-pagination slider_simple"></div>
                                <div class="popup-portfolio-hidden">
                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}

            </div>

        </div>
    );
}

export default Home;
