import {React, Component} from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import $ from 'jquery';
import ReactGA from 'react-ga';

const images = [
    './assets/portfolio/1.jpg',
    './assets/portfolio/1.jpg',
    './assets/portfolio/2.jpg',
    './assets/portfolio/3.jpg',
    './assets/portfolio/4.jpg',
    './assets/portfolio/5.jpg',
    './assets/portfolio/6.jpg',
    './assets/portfolio/7.jpg',
    './assets/portfolio/8.jpg',
    './assets/portfolio/9.jpg',
    './assets/portfolio/10.jpg',
    './assets/portfolio/11.jpg',
    './assets/portfolio/12.jpg',
    './assets/portfolio/14.jpg',
    './assets/portfolio/16.jpg',
    './assets/portfolio/18.jpg',
    './assets/portfolio/20.jpg',
    './assets/portfolio/22.jpg',
    './assets/portfolio/23.jpg',
    './assets/portfolio/24.jpg',
    './assets/portfolio/26.jpg',
    './assets/portfolio/28.jpg',
    './assets/portfolio/30.jpg',
    './assets/portfolio/32.jpg',



  ];

export default class Portfolio extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          photoIndex: 1,
          isOpen: false,
        };
      }

      componentDidMount () {

        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
        ReactGA.pageview('Maternity Gallery View');
        
        $('.mob-nav').on('click', function () {
            $(this).toggleClass('active');
            $(this).find('i').toggleClass('fa-bars fa-times');
            $('#topmenu').toggleClass('open');
            $('.header_top_bg.header_trans-fixed').toggleClass('open');
            if($('#topmenu').hasClass('active-socials')){
                $('.header_top_bg.header_trans-fixed').addClass('open');
            }
            $('body, html').toggleClass('no-scroll');
            return false;
        });

      }


    render() {
        const { photoIndex, isOpen } = this.state;
  return (
   <div>
    
    <div class="preloader-svg">
    <svg id="loaderSvg" class="loader__svg" xmlns="http://www.w3.org/2000/svg" height="200" viewBox="0 0 1024 300">
    <defs>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern1"
            viewBox="0 0 1024 300">
            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                   xlinkHref="assets/portfolio/1.jpg"
                   preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern2"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/2.png"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern3"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/3.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern4"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/4.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern5"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/5.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
        </defs>
        <text x="50%" y="20%" id="letter" dy="150"
              fill="url('#pattern1')">
            OMG
        </text>
    </svg>
</div>

    
<div class="main-wrapper   padding-desc  padding-mob ">
    <div class="header_top_bg ">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">

                    
                    <header class="right-menu ">
                        
                        <a href="/" class="logo">

                            <img src="./assets/images/Logo-Draft-Black.png"/> </a>
                        
                        
                        <a href="#" class="mob-nav">
                            <i class="fa fa-bars"></i>
                        </a>
                        

                        
                        <a href="#" class="aside-nav">
                            <span class="aside-nav-line line-1"></span>
                            <span class="aside-nav-line line-2"></span>
                            <span class="aside-nav-line line-3"></span>
                        </a>
                        

                        
                        <nav id="topmenu" class="topmenu">
                        <ul class="menu">
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children mega-menu menu-home">
                                    <a href="/home">HOME</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent ">
                                    <a href="#">PORTFOLIO</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/portfolio">Maternity Gallery</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/newbornportfolio">Newborn Gallery</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/collection">Maternity Gowns</a>
                                        </li>
                                    </ul>
                                      
                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="#">PRICING</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/investment">Maternity Session Pricing</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/newborninvestment">Newborn Session Pricing</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/bundleinvestment">Maternity & Newborn Bundle</a>
                                        </li>
                                    </ul>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/aboutus">ABOUT US</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/faq">FAQ</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/contact">ENQUIRE NOW</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="https://forms.gle/9WfQamuQ1jGTBWQ26" target="_blank">Model Search</a>
                                    
                                </li>
                               
                            </ul>
                            <div class="f-right">
                                <div class="whizz-top-social">
                                    <span class="social-icon fa fa-share-alt"></span>
                                    <ul class="social">
                                        <li><a href="https://www.facebook.com/ohmygoddessphotography" target="_blank"><i
                                                class="fa fa-facebook"></i></a></li>
                                        
                                        <li><a href="https://instagram.com/ohmygoddessphotography" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                               
                            </div>
                        </nav>
                        
                        <a href="#" class="socials-mob-but">
                            <i class="fa fa-share-alt"></i>
                        </a>
                    </header>
                </div>
            </div>

        </div>

    </div>

    <div class="container clearfix no-padd portfolio-single-content margin-lg-30b ">
        <div class="row">
            <div class="col-xs-12">
            </div>
        </div>
        <div class="row single-share">
            <div class="ft-part margin-lg-15b">
         
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
        
            </div>
        </div>

        <div class="container clearfix no-padd">
            <div class="gallery-single margin-lg-10b margin-xs-0b col-3" data-thumb="true" data-space="15">
                <div class="izotope-container">
                    <div class="grid-sizer"></div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:1 })} >
                    
                        <img data-lazy-src="./assets/portfolio/1.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:2 })} >
                    
                        <img data-lazy-src="./assets/portfolio/2.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:3 })} >
                    
                        <img data-lazy-src="./assets/portfolio/3.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:4 })} >
                    
                        <img data-lazy-src="./assets/portfolio/4.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:5 })} >
                    
                        <img data-lazy-src="./assets/portfolio/5.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:6 })} >
                    
                        <img data-lazy-src="./assets/portfolio/6.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:7 })} >
                    
                        <img data-lazy-src="./assets/portfolio/7.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:8 })} >
                    
                        <img data-lazy-src="./assets/portfolio/8.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:9 })} >
                    
                        <img data-lazy-src="./assets/portfolio/9.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:10 })} >
                    
                        <img data-lazy-src="./assets/portfolio/10.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:11 })} >
                    
                        <img data-lazy-src="./assets/portfolio/11.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:12 })} >
                    
                        <img data-lazy-src="./assets/portfolio/12.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:14 })} >
                    
                        <img data-lazy-src="./assets/portfolio/14.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:16 })} >
                    
                        <img data-lazy-src="./assets/portfolio/16.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:18 })} >
                    
                        <img data-lazy-src="./assets/portfolio/18.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:20 })} >
                    
                        <img data-lazy-src="./assets/portfolio/20jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:22 })} >
                    
                        <img data-lazy-src="./assets/portfolio/22.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:23 })} >
                    
                        <img data-lazy-src="./assets/portfolio/23.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:24 })} >
                    
                        <img data-lazy-src="./assets/portfolio/24.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:26 })} >
                    
                        <img data-lazy-src="./assets/portfolio/26.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:28 })} >
                    
                        <img data-lazy-src="./assets/portfolio/28.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:30 })} >
                    
                        <img data-lazy-src="./assets/portfolio/30.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
                    
                    </div>

                    <div class="item-single gallery-item three" onClick={() => this.setState({ isOpen: true, photoIndex:32 })} >
                    
                        <img data-lazy-src="./assets/portfolio/32.jpg"  src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Blandit " />
            
                    </div>
                   
                    {/*

                    <div class="item-single">
                        <a href="assets/images/stock-photo-138439815.jpg" class="gallery-item three ">
                            <img data-lazy-src="./assets/images/stock-photo-138439815.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Laoreet " />
                            <div class="info-content"
                                 data-content="Laoreet ">
                                <div class="vertical-align">
                                    <h5>Laoreet </h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="item-single">
                        <a href="assets/images/stock-photo-203371029.jpg" class="gallery-item three ">
                            <img data-lazy-src="./assets/images/stock-photo-203371029.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Mary" />
                            <div class="info-content"
                                 data-content="Mary">
                                <div class="vertical-align">
                                    <h5>Mary</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="item-single">
                        <a href="assets/images/stock-photo-189411801.jpg" class="gallery-item three ">
                            <img data-lazy-src="./assets/images/stock-photo-189411801.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Tim" />
                            <div class="info-content"
                                 data-content="Tim">
                                <div class="vertical-align">
                                    <h5>Tim</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="item-single">
                        <a href="assets/images/stock-photo-206124011.jpg" class="gallery-item three ">
                            <img data-lazy-src="./assets/images/stock-photo-206124011.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Aenean" />
                            <div class="info-content"
                                 data-content="Aenean">
                                <div class="vertical-align">
                                    <h5>Aenean</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="item-single">
                        <a href="assets/images/stock-photo-159062461-e1509005459415.jpg" class="gallery-item three ">
                            <img data-lazy-src="./assets/images/stock-photo-159062461-e1509005459415.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Sapien " />
                            <div class="info-content"
                                 data-content="Sapien ">
                                <div class="vertical-align">
                                    <h5>Sapien </h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="item-single">
                        <a href="assets/images/imgonline-com-ua-exifedithD4tlsX5EwRM.jpg" class="gallery-item three ">
                            <img data-lazy-src="./assets/images/imgonline-com-ua-exifedithD4tlsX5EwRM.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Dapibus" />
                            <div class="info-content"
                                 data-content="Dapibus">
                                <div class="vertical-align">
                                    <h5>Dapibus</h5>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div class="item-single">
                        <a href="assets/images/pexels-photo-91221.jpg" class="gallery-item three ">
                            <img data-lazy-src="./assets/images/pexels-photo-91221.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="Placerat" />
                            <div class="info-content"
                                 data-content="Placerat">
                                <div class="vertical-align">
                                    <h5>Placerat</h5>
                                </div>
                            </div>
                        </a>
                    </div> */}

                </div>
            </div>
        </div>
   

    </div>


    </div>
    
   </div>

    
  );
  }
}
