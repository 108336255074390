import logo from './logo.svg';
import { useEffect } from 'react';
import $ from 'jquery';
import ReactGA from 'react-ga';

function FAQ() {
    useEffect(()=>{

        ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
        ReactGA.pageview('FAQs View');

        $('.mob-nav').on('click', function () {
            $(this).toggleClass('active');
            $(this).find('i').toggleClass('fa-bars fa-times');
            $('#topmenu').toggleClass('open');
            $('.header_top_bg.header_trans-fixed').toggleClass('open');
            if($('#topmenu').hasClass('active-socials')){
                $('.header_top_bg.header_trans-fixed').addClass('open');
            }
            $('body, html').toggleClass('no-scroll');
            return false;
        });

    }) 
  return (
    <div className="App">
<div class="preloader-svg">
    <svg id="loaderSvg" class="loader__svg" xmlns="http://www.w3.org/2000/svg" height="200" viewBox="0 0 1024 300">
    <defs>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern1"
            viewBox="0 0 1024 300">
            <image xmlnsXlink="http://www.w3.org/1999/xlink"
                   xlinkHref="assets/portfolio/1.jpg"
                   preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern2"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/2.png"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern3"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/3.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern4"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/4.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
            <pattern x="0" y="0" width="1024" height="686" patternUnits="userSpaceOnUse" id="pattern5"
                     viewBox="0 0 1024 300">
                <image xmlnsXlink="http://www.w3.org/1999/xlink"
                       xlinkHref="assets/portfolio/5.jpg"
                       preserveAspectRatio="none" x="0" y="-200" width="1024" height="686"></image>
            </pattern>
        </defs>
        <text x="50%" y="20%" id="letter" dy="150"
              fill="url('#pattern1')">
            OMG
        </text>
    </svg>
</div>
<div class="main-wrapper">
    <div class="header_top_bg">
        <div class="container">
            <div class="row">
                <div class="col-xs-12">

                    
                    <header class="right-menu ">
                        
                        <a href="/" class="logo">

                        <img src="./assets/images/Logo-Draft-Black.png"/> </a>
                        

                        
                        <a href="#" class="mob-nav">
                            <i class="fa fa-bars"></i>
                        </a>
                        

                        
                        <a href="#" class="aside-nav">
                            <span class="aside-nav-line line-1"></span>
                            <span class="aside-nav-line line-2"></span>
                            <span class="aside-nav-line line-3"></span>
                        </a>
                        

                        
                        <nav id="topmenu" class="topmenu">
                        <ul class="menu">
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children mega-menu menu-home">
                                    <a href="/home">HOME</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="#">PORTFOLIO</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/portfolio">Maternity Gallery</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/newbornportfolio">Newborn Gallery</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/collection">Maternity Gowns</a>
                                        </li>
                                    </ul>
                                      
                                </li>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                 
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="#">PRICING</a>
                                    <ul class="sub-menu">
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/investment">Maternity Session Pricing</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/newborninvestment">Newborn Session Pricing</a>
                                        </li>
                                        <li class="menu-item menu-item-type-post_type menu-item-object-portfolio">
                                            <a href="/bundleinvestment">Maternity & Newborn Bundle</a>
                                        </li>
                                    </ul>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/aboutus">ABOUT US</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom current-menu-ancestor current-menu-parent ">
                                    <a href="/faq">FAQ</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="/contact">ENQUIRE NOW</a>
                                    
                                </li>
                                <li
                                        class="menu-item menu-item-type-custom menu-item-object-custom ">
                                    <a href="https://forms.gle/9WfQamuQ1jGTBWQ26" target="_blank">Model Search</a>
                                    
                                </li>
                               
                            </ul>
                            <div class="f-right">
                                <div class="whizz-top-social">
                                    <span class="social-icon fa fa-share-alt"></span>
                                    <ul class="social">
                                        <li><a href="https://www.facebook.com/ohmygoddessphotography" target="_blank"><i
                                                class="fa fa-facebook"></i></a></li>
                                        
                                        <li><a href="https://instagram.com/ohmygoddessphotography" target="_blank"><i class="fa fa-instagram"></i></a></li>
                                    </ul>
                                </div>
                               
                            </div>
                        </nav>
                        
                        <a href="#" class="socials-mob-but">
                            <i class="fa fa-share-alt"></i>
                        </a>
                    </header>
                </div>
            </div>

        </div>
    </div>
    <div class="container">
        <div class="hero">
            <div class="vc_row wpb_row vc_row-fluid vc_custom_1496837295647  margin-lg-40t margin-lg-80b margin-xs-0t margin-xs-30b"><div class="wpb_column vc_column_container vc_col-sm-6  margin-xs-40b"><div class="vc_column-inner "><div class="wpb_wrapper">
                <div class="team-member-wrap fullheight">
                    <div class="team-member fullheight full_height " >

                        <img data-lazy-src="./assets/portfolio/5.jpg" src="data:image/gif;base64,R0lGODdhAQABAIAAAAAAAMzMzCwAAAAAAQABAAACAkQBADs=" class="s-img-switch" alt="" />
                        <div class="info">
                            <h5 class="title">Oh My Goddess</h5>
                             
                            <div class="social">
                                <div class="vertical-align text-center wrap">

                                    <a href="https://www.facebook.com/ohmygoddessphotography" target="_blank">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                    <a href="https://www.instagram.com/ohmyGoddessphotography/" target="_blank">
                                        <i class="fa fa-instagram"></i>
                                    </a>
                                   

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div></div></div><div class="kswr-column-settings" data-minheight="0px" data-isminheight="off" data-theclasses="kswr_pnone kswr_mnone kswr_bnone"></div><div class="wpb_column vc_column_container vc_col-sm-6  margin-xs-40b"><div class="vc_column-inner "><div class="wpb_wrapper">
                <div class="row post-list simple enable_fullheight">




                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>Where are you located?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>We are based in Melbourne. We love to travel to beautiful locations for Maternity Sessions. Oh and we also do indoor Maternity sessions at our home studio. </p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>



                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>When is the best time to have the maternity photos done ?</h4>
                                <div class="post-box-desc">
                                <p style={{color:"black", fontFamily: "Encode Sans"}}>We recommend to schedule your maternity session between 32 and 36 weeks. We have personally experienced and have seen over the years that this is probably the most comfortable time in terms of mobility and the shape of the gorgeous baby bump. 
                                </p>
                                
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>Can my partner and children be part of the session?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>Yes Ofcourse. Our session is all about having an enjoyable experience and expressing how happy you are as a family</p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>How much time will it take for the session?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>Our Sessions usually go anwhere between 60-90 minutes. So best to allocate 90 minutes + your travel</p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>How far in advance do I have to book the session?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>We usually get booked a month in advance. So we suggest you get in touch with us when you sre around 26 weeks or earlier.</p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>Do you shoot on weekdays & Weekdays?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>We do take bookings for both weekdays & weekends. However our weekend slots are the ones that get booked first. So if you prefer weekends, it would be best if you book a session at the earliest.</p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>Will you share the RAW photos?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>Sorry we do not share our RAW photos. And we do not share it for a reason - Our photos speak on behalf of us and carry our reputation. The RAW photos are unedited and are pretty much like a draft version. We definitely do not want un-finished photos hanging on your wall.</p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-sm-12 col-xs-12">
                        <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>How long does it tale to get the photos?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>Post the session, we sort, touch up & share the watermarked photos for your preview in 7 business days. You can view the gallery and select the photos you like. They will be edited and final high-resolution photos will be shared in 5 days from the tie we receive the shortlist from you. </p>
                                    
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="post-box">
                            <div class="text text-dark">
                                <h4 class="post-box-title" style={{ fontFamily: "Encode Sans", fontWeight: "700"}}>Do you have a wardrobe for my partner & children?</h4>
                                <div class="post-box-desc">
                                    <p style={{color:"black", fontFamily: "Encode Sans"}}>We only have a collection of maternity gowns for the mum at this stage. However we are happy to provide suggestions around the attire for your partner & children</p>
                                    
                            </div>
                            </div>
                        </div>
                        



                    
                </div>

            </div></div></div><div class="kswr-column-settings" data-minheight="0px" data-isminheight="off" data-theclasses="kswr_pnone kswr_mnone kswr_bnone"></div></div><div class="kswr-row-element-back" data-classes="kswr_pnone kswr_mnone kswr_bnone"></div>
        </div>
    </div>
    </div>
    <footer id="footer" class="">
        <div class="container-fluid">
            <div class="copyright text-center">
            Oh My Goddess Photography © 2022
            </div>
        </div>
    </footer>


</div>

    
  )
}

export default FAQ